<template>
  <span @click.stop="clickHandel">
    <slot></slot>
    <span @click.stop="">
      <el-dialog
        :title="operateType === 'add' ? '新增机构' : '编辑机构'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="544px"
      >
        <div class="institutional-form-container">
          <el-form
            :model="formData"
            :rules="rules"
            ref="operateForm"
            label-width="100px"
            class="operate-form"
          >
            <el-form-item label="机构名称" prop="orgShortInfo">
              <el-input v-model="formData.orgShortInfo"></el-input>
            </el-form-item>
            <el-form-item label="机构简称" prop="orgName">
              <el-input v-model="formData.orgName"></el-input>
            </el-form-item>
            <el-form-item label="机构地址" prop="orgAddress">
              <el-input v-model="formData.orgAddress"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="connectPersonName">
              <el-input v-model="formData.connectPersonName"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机" prop="connectMobile">
              <el-input v-model="formData.connectMobile"></el-input>
            </el-form-item>
            <el-form-item label="联系人邮箱" prop="connectEmail">
              <el-input v-model="formData.connectEmail"></el-input>
            </el-form-item>
            <el-form-item label="机构归属" prop="parentId">
              <el-tree-select
                ref="group-select-tree"
                class="group-select-tree"
                v-model="formData.parentId"
                :selectParams="selectParams"
                :treeParams="treeParams"
              >
              </el-tree-select>
            </el-form-item>
            <el-form-item label="公司LOGO" prop="orgLogo">
              <el-upload
                class="logo-uploader"
                action="/api/file/upload"
                multiple
                :limit="1"
                :headers="{ token: getToken() }"
                :show-file-list="false"
                accept="image/png, image/jpeg"
                :on-success="onSuccessHandel"
              >
                <div slot="trigger" class="logo-uploader-container">
                  <img
                    class="upload-icon"
                    :src="require('@/assets/img/upload-icon.png')"
                  />
                </div>
                <div class="logo-view">
                  <img
                    v-if="!formData.orgLogo"
                    class="default-logo"
                    :src="require('@/assets/img/default-logo.png')"
                  />
                  <img v-else class="default-logo" :src="formData.orgLogo" />
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="平台名称" prop="platformName">
              <el-input v-model="formData.platformName"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" :loading="loading" @click="sureHandel"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </span>
  </span>
</template>

<script>
import axios from "@/utils/axios";
import "./OperateModal.less";
export default {
  name: "OperateModal",
  props: {
    operateType: {
      type: String,
      default: "add",
    },
    getCurrentOrgList: {
      type: Function,
      default: () => {},
    },
    fillData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      formData: {
        orgName: "",
        orgShortInfo: "",
        orgAddress: "",
        connectPersonName: "",
        connectMobile: "",
        connectEmail: "",
        parentId: "",
        orgLogo: "",
        platformName: "",
      },
      rules: {
        orgName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        orgShortInfo: [
          { required: true, message: "请输入机构简称", trigger: "blur" },
        ],
        orgAddress: [
          { required: true, message: "请输入机构地址", trigger: "blur" },
        ],
        connectPersonName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        connectMobile: [
          { required: true, message: "请输入联系人手机", trigger: "blur" },
          { validator: this.validatePhoneNumber, trigger: "blur" },
        ],
        connectEmail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: this.validateMail, trigger: "blur" },
        ],
        parentId: [{ required: true, message: "机构归属", trigger: "blur" }],
        platformName: [
          { required: true, message: "平台名称", trigger: "blur" },
        ],
        orgLogo: [{ required: true, message: "公司LOGO", trigger: "blur" }],
      },
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
    };
  },
  computed: {
    orgTreeData() {
      return this.$store.state.institutionalManagement.orgTreeData;
    },
    currentUserInfo() {
      return this.$store.state.currentUserInfo;
    },
  },
  methods: {
    getToken() {
      return localStorage.getItem("user-token");
    },
    async clickHandel() {
      if (this.operateType === "edit") {
        this.formData = this.fillData;
      }
      this.dialogVisible = true;
      const orgId = this.currentUserInfo.orgId;
      const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
      this.treeParams.data = [rs];
      this.$nextTick(() => {
        this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
      });
    },
    sureHandel() {
      this.$refs["operateForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.operateType === "add") {
            axios
              .post("/api/org/create", this.formData)
              .then(() => {
                this.$message({
                  message: "添加成功",
                  type: "success",
                });
                this.loading = false;
                this.$refs.operateForm.resetFields();
                this.getCurrentOrgList();
                this.dialogVisible = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          } else {
            axios
              .post("/api/org/edit", this.formData)
              .then(() => {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.loading = false;
                this.$refs.operateForm.resetFields();
                this.getCurrentOrgList();
                this.dialogVisible = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          }
        }
      });
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的手机号"));
      }
    },
    validateMail(rule, value, callback) {
      const reg =
        /^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\.)+[A-Za-z]{2,6}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的邮箱地址"));
      }
    },
    onSuccessHandel(response) {
      this.formData.orgLogo = response.data.path;
    },
  },
};
</script>
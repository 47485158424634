<template>
    <div class="institutional-management-container">
        <div class="institutional-info-container">
            <DetailInfo :detailInfo="currentUserInfo.org"/>
        </div>
        <div class="institutional-table-container">
            <div class="tool-bar-container">
                <div class="search-container">
                    <el-input
                        class="institutional-search-input"
                        placeholder="输入机构名称或者ID号搜索"
						suffix-icon="el-icon-search"
                        size="medium"
                        v-model="keyWord"
                        @change="changeHandel"
                    >
                    </el-input>
                </div>
                <div class="other-tools-container">
                    <span class="added-info-title">已添加子机构</span>
                    <span class="added-info-number">{{ orgListData.total }}</span>
                    <OperateModal :getCurrentOrgList="getCurrentOrgList">
						<el-button class="add-button add-button2" type="primary">新增机构</el-button>
                    </OperateModal>
                </div>
            </div>
            <div class="institutional-table">
                <div class="institutional-table-content">
                    <el-table 
                        style="width: 100%"
                        height="100%"
                        :data="orgListData.list"
                    >
                        <el-table-column
                            prop="orgShortInfo"
                            label="机构名称"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="orgName"
                            label="机构简称"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="connectPersonName"
                            label="联系人"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="connectMobile"
                            label="联系人手机"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="operateType"
                            label="操作"
                        >
                            <template v-slot="scope">
                                <OperateModal :operateType="'edit'" :getCurrentOrgList="getCurrentOrgList" :fillData="scope.row">
                                    <a class="operate-a">修改</a>   
                                </OperateModal>
                                <a class="operate-a" @click="deleteHandel(scope.row.id)">删除</a>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="institutional-table-page">
                    <el-pagination
                        background
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="orgListData.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DetailInfo from './components/DetailInfo';
import './Index.less';
import OperateModal from './components/OperateModal';
import axios from '@/utils/axios';
export default {
    name: 'InstitutionalManagement',
    components: {
        DetailInfo,
        OperateModal
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            keyWord: '',
            currentUserInfo: {}
        }
    },
    computed: {
        orgListData() {
            return this.$store.state.institutionalManagement.orgListData;
        }
    },
    methods: {
        async getCurrentOrgList() {
            const {
                page,
                pageSize,
                keyWord
            } = this;
            const {
                orgId,
                // orgLeave
            } = this.currentUserInfo.org;
            this.$store.dispatch({ type: 'institutionalManagement/fetchOrgListData', payload: {
                page,
                pageSize,
                keyWord,
                orgId: orgId,
                // orgLeave: orgLeave
            }});
        },
        changeHandel() {
            this.page = 1;
            this.getCurrentOrgList();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getCurrentOrgList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getCurrentOrgList();
        },
        deleteHandel(id) {
            this.$confirm('确认删除此机构, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/org/remove/${id}`).then(
                    () => {
                        this.$message.success('删除成功');
                        this.getCurrentOrgList();
                    }
                ).catch(err => {
                    this.$message.error(err);
                });
            });
        }
    },
    async mounted() {
        const currentUserInfo = localStorage.getItem('currentUser');
        if (currentUserInfo) {
            this.currentUserInfo = JSON.parse(currentUserInfo);
            await this.getCurrentOrgList();
        }
    }
}
</script>
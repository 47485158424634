<template>
	<div class="detail-info-container">
		<div class="detail-info-content">
			<div class="info-left">
				<!-- <div class="info-item flex flex-ac">
					<div class="info-item-label">机 构 ID：</div>
					<div class="info-item-vlaue">{{ detailInfo.orgId }}</div>
					<div class="copy-button">复制</div>
				</div> -->
				<div class="info-item">
					<div class="info-item-label">机 构 名 称：</div>
					<div class="info-item-vlaue">{{ detailInfo.orgShortInfo }}</div>
				</div>
				<div class="info-item">
					<div class="info-item-label">机 构 简 称：</div>
					<div class="info-item-vlaue">{{ detailInfo.orgName }}</div>
				</div>
				<div class="info-item">
					<div class="info-item-label">机 构 地 址：</div>
					<div class="info-item-vlaue">{{ detailInfo.orgAddress }}</div>
				</div>
				<div class="info-item">
					<div class="info-item-label">联系人姓名：</div>
					<div class="info-item-vlaue">{{ detailInfo.connectPersonName }}</div>
				</div>
				<div class="info-item">
					<div class="info-item-label">联系人手机：</div>
					<div class="info-item-vlaue">{{ detailInfo.connectMobile }}</div>
				</div>
				<div class="info-item">
					<div class="info-item-label">联系人邮箱：</div>
					<div class="info-item-vlaue">{{ detailInfo.connectEmail }}</div>
				</div>
			</div>
			<div class="info-right">
				<div class="info-item">
					<div class="info-item-label ftr">平台名称：</div>
					<div class="info-item-vlaue">{{ detailInfo.platformName }}</div>
				</div>
				<div class="info-logo-item">
					<div class="info-item-label ftr">平台LOGO：</div>
					<div class="info-logo-value">
						<img v-if="!detailInfo.orgLogo" class="default-logo" :src="require('@/assets/img/default-logo.png')" />
						<img v-else class="default-logo" :src="detailInfo.orgLogo" />
					</div>
				</div>
			</div>
			<!-- <div class="edit-logo-button">
				<el-upload
					class="upload-logo"
					action="/api/file/upload"
					multiple
					:limit="1"
					:headers="{ token: getToken() }"
					:show-file-list="false"
					accept="image/png, image/jpeg"
					:on-success="onSuccessHandel"
				>
					<el-button type="primary">修改</el-button>
				</el-upload>
			</div> -->
		</div>
	</div>
</template>

<script>
import axios from '@/utils/axios';
import './DetailInfo.less';
	export default {
		name: 'DetailInfo',
		props: {
			detailInfo: {
				type: Object,
				default: () => ({
					connectEmail: "",
					connectMobile: "",
					connectPersonName: "",
					id: '',
					orgAddress: "",
					orgId: "",
					orgLogo: "",
					orgName: "",
					orgShortInfo: "",
					platformName: "",
					parentId: ""
				})
			}
		},
		methods: {
			getToken() {
				return localStorage.getItem('user-token');
			},
			onSuccessHandel(response) {
				const path = response.data.path;
				axios.post('/api/org/edit', { ...this.detailInfo, orgLogo: path }).then(
					() => {
						this.$store.dispatch({ type: 'getCurrentUserInfo' }).then(rs => {
							localStorage.setItem('currentUser', JSON.stringify(rs));
						});
					}
				).catch(err => {
					this.$message.error(err);
				});
			}
		}
	}
</script>
